import appRoleSelector from "./role_selector";
import appRPTParser from "./rpt_parser";
import appPlayerDashboard from "./player/show";
import appNotification from "./notifications";
import appServer from "./servers";
import appCharacterCounter from "./character_counter";
import appXM8NotificationRouting from "./xm8_notification_routing";
import appUserDefaults from "./user_defaults";
import appUserAliases from "./user_aliases";

import Vue from "vue/dist/vue.esm.js";
import TurbolinksAdapter from "vue-turbolinks";
import axios from "axios";
import VTooltip from "v-tooltip";
import Multiselect from "vue-multiselect";

import('vue-multiselect/dist/vue-multiselect.min.css');

Vue.use(VTooltip);
Vue.use(TurbolinksAdapter);

Vue.component("multiselect", Multiselect);

const APPS = {
  ".role-selector": appRoleSelector,
  "#player-dashboard": appPlayerDashboard,
  "#rpt-parser": appRPTParser,
  "#notifications": appNotification,
  "#app-server": appServer,
  "#character-counter": appCharacterCounter,
  "#xm8-notification-routing": appXM8NotificationRouting,
  "#user-defaults": appUserDefaults,
  "#user-aliases": appUserAliases,
};

// Add the CSRF Token by default
axios.defaults.headers.common["X-CSRF-Token"] = document.querySelector(
  "meta[name=csrf-token]"
).content;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

$(window).on("load", () => {
  _.each(APPS, (app, id) => {
    // Check if the id is on our page
    if (_.isEmpty($(id))) return;

    if (_.startsWith(id, ".")) {
      $(id).each(function () {
        app(this);
      });
    } else {
      // Call the app
      app(id);
    }
  });
});
