import Vue from 'vue/dist/vue.esm.js';
import {
    getData
} from "../utilities";

export default function (elem) {
    const data = getData(elem, ["content"]);

    const app = new Vue({
        el: elem,
        data: {
          content: data.content,
          maxCharacterCount: 1500
        },
        computed: {
          characterCount: function() {
            if (_.isEmpty(this.content)) return 0;

            return _.size(this.content);
          },
          limitReached: function() {
            return this.characterCount >= this.maxCharacterCount;
          }
        },
        methods: {
          onKeyDown: function() {
            if (!this.limitReached) return;

            this.content = this.content.substring(0, this.maxCharacterCount);
          }
        }
    });
}
