import Vue from 'vue/dist/vue.esm.js';
import {
    getData
} from "../utilities";
import VueMarkdown from 'vue-markdown';
import {
    Slider
} from 'vue-color';
import faker from "faker";
import * as UIkit from "uikit";
import Axios from "axios";

export default function (elem) {
    let data = getData(elem, ["notifications", "community-id", "servers", "limits", "community-public-id"]);

    const app = new Vue({
        el: elem,
        components: {
            VueMarkdown,
            "slider-picker": Slider
        },
        mounted: function () {
            $(elem).removeClass("uk-hidden");
            this.createPreviewNames();
        },
        data: {
            servers: data.servers,
            notifications: data.notifications,
            community_public_id: data["community-public-id"],
            community_id: data["community-id"],
            limits: data.limits,
            notificationTypes: [{
                category: "XM8",
                type: "base-raid"
            }, {
                category: "XM8",
                type: "flag-stolen"
            }, {
                category: "XM8",
                type: "flag-restored"
            }, {
                category: "XM8",
                type: "protection-money-due"
            }, {
                category: "XM8",
                type: "protection-money-paid"
            }, {
                category: "XM8",
                type: "charge-plant-started"
            }, {
                category: "XM8",
                type: "grind-started"
            }, {
                category: "XM8",
                type: "hack-started"
            }, {
                category: "XM8",
                type: "flag-steal-started"
            }, {
                category: "XM8",
                type: "marxet-item-sold"
            }, {
                category: "Gambling",
                type: "won"
            }, {
                category: "Gambling",
                type: "loss"
            }, {
                category: "Player",
                type: "money"
            }, {
                category: "Player",
                type: "locker"
            }, {
                category: "Player",
                type: "respect"
            }, {
                category: "Player",
                type: "heal"
            }, {
                category: "Player",
                type: "kill"
            }],

            /*
                Global Variables
                    - serverID -> The server's ID
                    - serverName -> The server's Name
                    - communityID -> The server's Community ID

                Gambling Variables
                    - userName -> User's name
                    - userTag -> User's Tag
                    - amountChanged -> Amount of poptabs won
                    - amountGambled -> Amount of poptabs gambled
                    - lockerBefore -> Amount of poptabs in the players locker before the gamble
                    - lockerAfter -> Amount of poptabs in the players locker after the gamble

                XM8 Variables
                    - territoryID -> The territory's ID
                    - territoryName -> The territory's Name

                MarXet Variables
                    - amount -> The amount of poptabs received for the item
                    - item -> The item sold
            */
            messageVariables: {
                global: [
                    "<code class='select-all'>{{ serverID }}</code> for the server's ID",
                    "<code class='select-all'>{{ serverName }}</code> for server's name",
                    "<code class='select-all'>{{ communityID }}</code> for your community ID",
                    "<code class='select-all'>{{ userName }}</code> for the player's name",
                    "<code class='select-all'>{{ userTag }}</code> for the player's @tag"
                ],
                gambling: [
                    "<code class='select-all'>{{ amountChanged }}</code> for the amount of poptabs won/lost",
                    "<code class='select-all'>{{ amountGambled }}</code> for the amount of poptabs gambled",
                    "<code class='select-all'>{{ lockerBefore }}</code> for the player's locker amount before gambling",
                    "<code class='select-all'>{{ lockerAfter }}</code> for player's locker amount after gambling"
                ],
                xm8: [
                    "<code class='select-all'>{{ territoryID }}</code> for the territory's ID",
                    "<code class='select-all'>{{ territoryName }}</code> for the territory's name"
                ],
                marxet: [
                    "<code class='select-all'>{{ item }}</code> for the item sold",
                    "<code class='select-all'>{{ amount }}</code> for the poptab amount"
                ],
                playerKillHeal: [
                    "<code class='select-all'>{{ targetUID }}</code> for the target player's Steam UID"
                ],
                playerMoneyRespect: [
                    "<code class='select-all'>{{ targetUID }}</code> for the target player's Steam UID",
                    "<code class='select-all'>{{ modifiedAmount }}</code> for the target player's amount that was modified",
                    "<code class='select-all'>{{ previousAmount }}</code> for the target player's amount before it was modified",
                    "<code class='select-all'>{{ newAmount }}</code> for the target's final amount after the change"
                ]
            },
            notificationBeingEdited: {},
            messagePreview: {},
            colorPresets: [{
                name: "Red",
                hex: "#C62551"
            }, {
                name: "Blue",
                hex: "#3ED3FB"
            }, {
                name: "Green",
                hex: "#9FDE3A"
            }, {
                name: "Yellow",
                hex: "#DECA39"
            }, {
                name: "Purple",
                hex: "#793ADE"
            }, {
                name: "Pink",
                hex: "#DE3A9F"
            }, {
                name: "Orange",
                hex: "#DE7839"
            }, {
                name: "White",
                hex: "#FFFFFF"
            }, {
                name: "Random",
                hex: "random"
            }, {
                name: "Custom",
                hex: "custom"
            }],
            selectedColorHex: "#3ED3FB",
            newNotification: {},
            filters: [{
                name: "All",
                value: "all"
            }, {
                name: "XM8 (All)",
                value: "xm8_all"
            }, {
                name: "Gambling (All)",
                value: "gambling_all"
            }, {
                name: "Player (All)",
                value: "player_all"
            }, {
                name: "XM8 (Base Raid)",
                value: "xm8_base-raid"
            }, {
                name: "XM8 (Flag Stolen)",
                value: "xm8_flag-stolen"
            }, {
                name: "XM8 (Flag Restored)",
                value: "xm8_flag-restored"
            }, {
                name: "XM8 (Protection Money Due)",
                value: "xm8_protection-money-due"
            }, {
                name: "XM8 (Protection Money Paid)",
                value: "xm8_protection-money-paid"
            }, {
                name: "XM8 (Charge Plant Started)",
                value: "xm8_charge-plant-started"
            }, {
                name: "XM8 (Grind Started)",
                value: "xm8_grind-started"
            }, {
                name: "XM8 (Hack Started)",
                value: "xm8_hack-started"
            }, {
                name: "XM8 (Flag Steal Started)",
                value: "xm8_flag-steal-started"
            }, {
                name: "XM8 (MarXet Item Sold)",
                value: "xm8_marxet-item-sold"
            }, {
                name: "Gambling (Won)",
                value: "gambling_won"
            }, {
                name: "Gambling (Loss)",
                value: "gambling_loss"
            }, {
                name: "Player (Money)",
                value: "player_money"
            }, {
                name: "Player (Locker)",
                value: "player_locker"
            }, {
                name: "Player (Respect)",
                value: "player_respect"
            }, {
                name: "Player (Heal)",
                value: "player_heal"
            }, {
                name: "Player (Kill)",
                value: "player_kill"
            }],
            selectedFilter: "all",
            processing: false
        },
        computed: {
            filteredNotifications: function () {
                let notifications = _.cloneDeep(this.notifications);
                let [category, type] = this.selectedFilter.split("_");

                // We want all the notifications
                if (category === "all") return _.orderBy(notifications, ["notification_category", "notification_type"], ["desc", "asc"])

                // We just want a category
                if (type === "all") {
                    return _.filter(notifications, {
                        notification_category: category
                    });
                }

                // We want category and type
                return _.filter(notifications, {
                    notification_category: category,
                    notification_type: type
                });
            },

            newNotificationTitleCharCount: function () {
                if (_.isEmpty(this.newNotification)) return 0;
                return _.size(this.newNotification.notification_title);
            },

            newNotificationDescriptionCharCount: function () {
                if (_.isEmpty(this.newNotification)) return 0;
                return _.size(this.newNotification.notification_description);
            },

            editNotificationTitleCharCount: function () {
                if (_.isEmpty(this.notificationBeingEdited)) return 0;
                return _.size(this.notificationBeingEdited.notification_title);
            },

            editNotificationDescriptionCharCount: function () {
                if (_.isEmpty(this.notificationBeingEdited)) return 0;
                return _.size(this.notificationBeingEdited.notification_description);
            },

            disableNotificationCreateButton: function () {
                if (this.processing) return true;
                if (this.newNotificationTitleCharCount >= this.limits.TITLE_LENGTH_MAX) return true;
                if (this.newNotificationDescriptionCharCount >= this.limits.DESCRIPTION_LENGTH_MAX) return true;

                return false;
            },

            disableNotificationSaveButton: function () {
                if (this.processing) return true;
                if (this.editNotificationTitleCharCount >= this.limits.TITLE_LENGTH_MAX) return true;
                if (this.editNotificationDescriptionCharCount >= this.limits.DESCRIPTION_LENGTH_MAX) return true;

                return false;
            }
        },
        methods: {
            isMobile: function () {
                return window.innerWidth < 1600;
            },
            notificationColor: function (notif) {
                if (notif.notification_color != "random") return notif.notification_color;

                return _.sample(this.colorPresets).hex;
            },
            defaultNotification: function () {
                return _.clone({
                    notification_category: "xm8",
                    notification_type: "base-raid",
                    notification_title: "",
                    notification_description: "",
                    notification_color: this.colorPresets[1].hex
                });
            },
            previewNotification: function (notif, string) {
                string = string
                    .replace(/{{\s*serverid\s*}}/gi, this.messagePreview.serverID)
                    .replace(/{{\s*servername\s*}}/gi, this.messagePreview.serverName)
                    .replace(/{{\s*communityid\s*}}/gi, this.messagePreview.communityID)
                    .replace(/{{\s*username\s*}}/gi, this.messagePreview.userName)
                    .replace(/{{\s*usertag\s*}}/gi, this.messagePreview.userTag);

                switch (notif.notification_type) {
                    case "base-raid":
                    case "flag-stolen":
                    case "flag-restored":
                    case "protection-money-due":
                    case "protection-money-paid":
                    case "charge-plant-started":
                    case "grind-started":
                    case "hack-started":
                    case "flag-steal-started":
                        string = string
                            .replace(/{{\s*territoryname\s*}}/gi, this.messagePreview.territoryName)
                            .replace(/{{\s*territoryid\s*}}/gi, this.messagePreview.territoryID);
                        break;
                    case "marxet-item-sold":
                        string = string
                            .replace(/{{\s*item\s*}}/gi, this.messagePreview.marxetItem)
                            .replace(/{{\s*amount\s*}}/gi, this.messagePreview.marxetAmount);
                        break;
                    case "won":
                    case "loss":
                        string = string
                            .replace(/{{\s*amountchanged\s*}}/gi, this.messagePreview.amountChanged)
                            .replace(/{{\s*amountgambled\s*}}/gi, this.messagePreview.amountGambled)
                            .replace(/{{\s*lockerbefore\s*}}/gi, this.messagePreview.lockerBefore)
                            .replace(/{{\s*lockerafter\s*}}/gi, this.messagePreview.lockerAfter);
                        break;
                    case "kill":
                    case "heal":
                        string = string.replace(/{{\s*targetuid\s*}}/gi, this.messagePreview.targetUID);
                        break;
                    case "money":
                    case "locker":
                    case "respect":
                        string = string
                            .replace(/{{\s*targetuid\s*}}/gi, this.messagePreview.targetUID)
                            .replace(/{{\s*modifiedamount\s*}}/gi, this.messagePreview.modifiedAmount)
                            .replace(/{{\s*previousamount\s*}}/gi, this.messagePreview.previousAmount)
                            .replace(/{{\s*newamount\s*}}/gi, this.messagePreview.newAmount);
                        break;
                }

                return string;
            },
            getMessageInstructions: function (notif) {
                let instructions = `${this.messageVariables.global.join("<br>")}<br>`;

                switch (notif.notification_type) {
                    case "base-raid":
                    case "flag-stolen":
                    case "flag-restored":
                    case "protection-money-due":
                    case "protection-money-paid":
                    case "charge-plant-started":
                    case "grind-started":
                    case "hack-started":
                    case "flag-steal-started":
                        instructions += `${this.messageVariables.xm8.join("<br>")}<br>`;
                        break;
                    case "marxet-item-sold":
                        instructions += `${this.messageVariables.marxet.join("<br>")}<br>`;
                        break;
                    case "won":
                    case "loss":
                        instructions += `${this.messageVariables.gambling.join("<br>")}<br>`;
                        break;
                    case "kill":
                    case "heal":
                        instructions += `${this.messageVariables.playerKillHeal.join("<br>")}<br>`;
                        break;
                    case "money":
                    case "locker":
                    case "respect":
                        instructions += `${this.messageVariables.playerMoneyRespect.join("<br>")}<br>`;
                        break;
                }

                return instructions;
            },
            discardChanges: function () {
                this.notificationBeingEdited = {};
                this.newNotification = {};
            },
            editNotification: function (notif) {
                // Reset this so it won't show
                this.newNotification = {};

                // Check to see if we've selected random or picked a custom color
                if (!_.some(this.colorPresets, {
                    hex: notif.notification_color
                }) && notif.notification_color !== "random") {
                    this.selectedColorHex = "custom";
                } else {
                    this.selectedColorHex = notif.notification_color;
                }

                // Clone and preview
                this.notificationBeingEdited = _.cloneDeep(notif);
                this.setNotificationPreview(notif);

                Vue.nextTick(() => {
                    if (!_.isEmpty($("#edit-notification")) && this.isMobile()) {
                        UIkit.modal($("#edit-notification")).show();
                    }
                });
            },
            setNotificationPreview: function (notif) {
                Vue.set(this.messagePreview, "title", this.previewNotification(notif, notif.notification_title));
                Vue.set(this.messagePreview, "message", this.previewNotification(notif, notif.notification_description));
            },
            setNotificationTitle: _.debounce(function (e, notif) {
                Vue.set(notif, "notification_title", $(e.target).val());
                Vue.set(this.messagePreview, "title", this.previewNotification(notif, notif.notification_title));
            }, 200),
            setNotificationMessage: _.debounce(function (e, notif) {
                Vue.set(notif, "notification_description", $(e.target).val());
                Vue.set(this.messagePreview, "message", this.previewNotification(notif, notif.notification_description));
            }, 200),
            setNotificationType: function (notif) {
                switch (notif.notification_type) {
                    case "money":
                    case "locker":
                    case "respect":
                    case "heal":
                    case "kill":
                        notif.notification_category = "player";
                        break;
                    case "won":
                    case "loss":
                        notif.notification_category = "gambling";
                        break;
                    default:
                        notif.notification_category = "xm8";
                        break;
                }

                this.setNotificationPreview(notif);
            },
            createPreviewNames: function () {
                this.newNotification = {};
                this.notificationBeingEdited = {};

                let serverName = faker.lorem.words(5);
                let serverID = `${this.community_id}_${faker.lorem.word()}`;
                let userName = faker.internet.userName();

                if (!_.isEmpty(this.servers)) {
                    let server = _.sample(this.servers);
                    serverName = !_.isEmpty(server.name) ? server.name : faker.lorem.words(5);
                    serverID = server.id
                }

                let targetUID = "";
                for (let i = 0; i < 18; i++) {
                    targetUID += faker.random.number(9);
                }

                this.messagePreview = {
                    territoryID: faker.random.alphaNumeric(5),
                    territoryName: faker.company.companyName(),
                    serverID: serverID,
                    serverName: serverName,
                    communityID: this.community_id,
                    marxetItem: faker.commerce.productName(),
                    marxetAmount: faker.commerce.price(1, 500000, 0, ""),
                    userName: userName,
                    userTag: `@${userName}`,
                    amountChanged: faker.commerce.price(1, 500000, 0, ""),
                    amountGambled: faker.commerce.price(1, 500000, 0, ""),
                    lockerBefore: faker.commerce.price(1, 500000, 0, ""),
                    lockerAfter: faker.commerce.price(1, 500000, 0, ""),
                    targetUID: targetUID,
                    modifiedAmount: faker.commerce.price(1, 500000, 0, ""),
                    previousAmount: faker.commerce.price(1, 500000, 0, ""),
                    newAmount: faker.commerce.price(1, 500000, 0, ""),
                };
            },
            updateNotificationColor: function (event, notif) {
                Vue.set(notif, "notification_color", event.hex);
            },
            onColorChange: function (notif) {
                if (this.selectedColorHex == "custom") return;

                Vue.set(notif, "notification_color", this.selectedColorHex);
            },
            showNewNotification: function () {
                this.newNotification = this.defaultNotification();
                this.notificationBeingEdited = {};
                Vue.set(this.messagePreview, "title", "");
                Vue.set(this.messagePreview, "message", "");

                Vue.nextTick(() => {
                    let modal = $("#new-notification");
                    if (!_.isEmpty(modal) && this.isMobile()) {
                        UIkit.modal($("#new-notification")).show();
                    }
                });
            },
            createNotification: function () {
                if (this.disableNotificationCreateButton) return;

                this.setProcessing(true);

                Axios.post(`/communities/${this.community_public_id}/notifications`, {
                    notification: this.newNotification
                }).then((response) => {
                    this.notifications = response.data.notifications;
                    Toaster.success(`Notification Created!`);
                    this.newNotification = {};
                }).catch((err) => {
                    console.log(err);
                    Toaster.error("Failed to create new notification. Please try again later");
                }).finally(() => {
                    this.setProcessing(false);
                });
            },
            saveNotification: function () {
                if (this.disableNotificationSaveButton) return;

                this.setProcessing(true);

                Axios.put(`/communities/${this.community_public_id}/notifications/${this.notificationBeingEdited.id}`, {
                    notification: this.notificationBeingEdited
                }).then((response) => {
                    this.notifications = response.data.notifications;
                    Toaster.success("Notification updated");
                }).catch((err) => {
                    console.log(err);
                    Toaster.error("Failed to save notification. Please try again later");
                }).finally(() => {
                    this.setProcessing(false);
                });
            },
            deleteNotification: function (notif) {
                if (this.processing) return;
                if (!confirm("Are you sure you want to delete?")) return;

                this.setProcessing(true);

                Axios
                    .delete(`/communities/${this.community_public_id}/notifications/${notif.id}`)
                    .then((response) => {
                        this.notifications = response.data.notifications;
                        Toaster.success("Notification deleted");
                    })
                    .catch((err) => {
                        console.log(err);
                        Toaster.error("Failed to delete notification. Please try again later");
                    })
                    .finally(() => {
                        this.setProcessing(false);
                    });
            },
            setProcessing: function (state) {
                if (state) {
                    $(".dashboard-panel").addClass("uk-hidden");
                    $("#mobile-panel").fadeOut();
                    $("#spinner").removeClass("uk-hidden");
                } else {
                    $(".dashboard-panel").removeClass("uk-hidden");
                    $("#mobile-panel").fadeIn();
                    $("#spinner").addClass("uk-hidden");
                }

                this.processing = state;
            }
        }
    });
}
