import Vue from 'vue/dist/vue.esm.js';
import axios from 'axios';
import {
    getData
} from "../../utilities";
import * as UIkit from "uikit";

export default function (elem) {
    const data = getData(elem, ["id", "activepanel", "alreadyregistered"]);

    const app = new Vue({
        el: elem,
        mounted: function () {
            this.switchPanel(data.activepanel || "profile");
            $(elem).removeClass("uk-hidden");

            if (data.alreadyregistered) {
                Vue.nextTick(() => {
                    UIkit.modal("#already-registered").show();
                });
            }
        },
        data: {
            discord_id: data.id,
            panels: {
                profile: {
                    name: "My Profile",
                    selected: true,
                    visible: true
                }
            }
        },
        methods: {
            switchPanel: function (panel) {
                _.each(this.panels, (info, key) => {
                    if (key == panel) {
                        Vue.set(info, "visible", true);
                        Vue.set(info, "selected", true);
                    } else {
                        Vue.set(info, "visible", false);
                        Vue.set(info, "selected", false);
                    }
                });

                if (panel === "landing") {
                    history.pushState('', 'Player Dashboard | Exile Server Manager', `/users/${this.discord_id}`);
                } else {
                    history.pushState('', 'Player Dashboard | Exile Server Manager', `/users/${this.discord_id}/${panel}`);
                }
            },
            discordIconFallback: function (e) {
                e.target.src = "https://discordapp.com/assets/6debd47ed13483642cf09e832ed0bc1b.png";
            },
            cancelTransfer: function () {
                axios.post(`/users/${this.discord_id}/cancel_transfer`).catch(err => console.log(err));
            }
        }
    });
}
