import _ from "lodash";

export function getData(elem, tags) {
    let data = {};
    elem = $(elem);
    _.each(tags, (tag) => {
        if (_.startsWith(tag, "data-")) {
            tag = _.trimStart(tag, "data-");
        }
        data[tag] = elem.data(tag);
        elem.removeAttr(`data-${_.kebabCase(tag)}`);
    });
    return data;
}

export const COLORS = {
    red: "#C62551",
    blue: "#3ED3FB",
    green: "#9FDE3A",
    yellow: "#DECA39",
    orange: "#c64a25",
    purple: "#793ADE",
    pink: "#DE3A9F",
    white: "#FFFFFF"
};
