import Vue from 'vue/dist/vue.esm.js';
import _ from "lodash";
import {
    getData,
    COLORS
} from "../utilities";
import AjaxMethods from "./methods/ajax";
import * as UIkit from "uikit";

// This is binding to a class name, so elem is actually a DOM object
export default function (elem) {
    let data = getData(elem, [
        // Server data
        "community", "channels", "users",

        // Player data
        "user", "communities",

        // Shared data
        "servers", "types", "pendingRequests", "typePresets"
    ]);

    const app = new Vue({
        el: elem,
        mixins: [AjaxMethods],
        data: {
            // Server data
            community: data.community,
            channels: data.channels,
            users: data.users,

            // Player data
            user: data.user,
            communities: data.communities,

            // Shared data
            servers: data.servers,
            types: data.types,
            typePresets: data.typePresets,
            pendingRequests: data.pendingRequests,
            pendingRequestsInputDisabled: false,
            newRouteCommunity: undefined,
            newRouteChannel: undefined,
            newRouteServers: [],
            newRouteUsers: [],
            newRouteTypes: [],
            newRouteServerRadio: "any",
            newRouteTypesRadio: "any",
            newRouteInputDisabled: false,
            editRouteCommunity: undefined,
            editRouteChannel: undefined,
            editRouteSelectedServer: undefined
        },
        mounted: function () {
            let colors = _.values(COLORS);
            for (let request of this.pendingRequests) {
                Vue.set(request, "color", _.sample(colors));
            }

            UIkit.util.on("#create-new-route-modal", "beforeshow", () => {
                this.resetNewRouteVars();
            });
        },
        computed: {
            createRequestButtonEnabled: function () {
                return !this.newRouteInputDisabled && this.newRouteChannel && (this.newRouteCommunity || this.newRouteUsers);
            },
            availableRouteData: function () {
                let routes = [];

                if (this.currentContext === this.community) {
                    if (!this.editRouteChannel) return [];

                    routes = this.editRouteChannel.routes;
                } else {
                    if (!this.editRouteCommunity) return [];

                    routes = _.flatMap(this.editRouteCommunity.channels, function (category) {
                        return _.flatMap(category.channels, (channel) => channel.routes);
                    });
                }

                // A route is considered editable if it has available types to edit
                return _.filter(routes, (route) => !_.isEmpty(this.availableServers(route)));
            }
        },
        methods: {
            availableServers: function (route) {
                let servers = _.filter(route.servers, (server) => {
                    return _.isUndefined(server.deleted) && !_.isEmpty(this.availableTypes(server));
                });

                return _.sortBy(servers, ["name"]);
            },
            availableTypes: function (server) {
                return _.filter(server.types, (type) => {
                    return _.isUndefined(type.deleted);
                });
            },
            requestSelectAllCheckbox: function (request) {
                return this.$refs[`request-${request.user.id}-${request.server.id}-${request.channel.id}-select-all`][0];
            },
            toggleRequestType: function (request, type, event) {
                let checked = event.target.checked;

                if (_.isUndefined(type)) {
                    _.each(request.types, (type) => { Vue.set(type, "selected", checked) });
                } else {
                    Vue.set(type, "selected", checked);

                    let allTypesSize = request.types.length;
                    let selectedTypesSize = _.filter(request.types, "selected").length;
                    let selectAllCheckbox = this.requestSelectAllCheckbox(request);

                    selectAllCheckbox.checked = (selectedTypesSize === allTypesSize);
                    selectAllCheckbox.indeterminate = (selectedTypesSize > 0 && selectedTypesSize < allTypesSize);
                }
            },
            resetNewRouteVars: function () {
                this.newRouteCommunity = this.editRouteCommunity || undefined;
                this.newRouteChannel = this.editRouteChannel || undefined;
                this.newRouteServers = [];
                this.newRouteUsers = [];
                this.newRouteTypes = [];
                this.newRouteServerRadio = "any";
                this.newRouteTypesRadio = "any";
            },
            typeTooltip: function (tooltipName, type, route_data) {
                let tooltips = {
                    enable: [
                        `${route_data.user.name} has not accepted this request yet. ESM will send a message to <span class="esm-text-color-toast-blue">#${route_data.channel.name}</span> if they accept it`,
                        `Click to permanently stop routing ${type.name} notifications to this channel`
                    ],
                    delete: [
                        `Click to delete pending request`,
                        `Click to permanently stop routing ${type.name} notifications to this channel`
                    ]
                };

                return tooltips[tooltipName][type.editable ? 1 : 0];
            },
            updateAvailableRoutes: function (typeIds, action) {
                let vueInstance = this;

                let servers = _.flatMap(this.availableRouteData, "servers");

                // Cache the servers up front so this code can support one or many acceptances, and doesn't require looping over every server for each ID
                let serverIndexByTypeId = {};
                _.each(servers, (server, index) => {
                    _.each(server.types, (type) => {
                        serverIndexByTypeId[type.id] = index;
                    });
                });

                // Update the types that were accepted/declined
                _.each(typeIds, function (typeId) {
                    let server = servers[serverIndexByTypeId[typeId]];
                    if (server == null) return;

                    let type = _.find(server.types, ["id", typeId]);
                    if (_.isUndefined(type)) return;

                    if (action === "decline") {
                        return _.pull(server.types, type);
                    }

                    if (vueInstance.currentContext == vueInstance.community) {
                        Vue.set(type, "community_accepted", true);
                    } else {
                        Vue.set(type, "user_accepted", true);
                    }

                    if (type.user_accepted && type.community_accepted) {
                        Vue.set(type, "enabled", true);
                        Vue.set(type, "editable", true);
                    }
                });
            }
        }
    });
}
