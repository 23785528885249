import axios from 'axios';
import Vue from 'vue/dist/vue.esm.js';

export default {
    computed: {
        currentContext: function () {
            return this.community || this.user;
        },
        urlPrefix: function () {
            return (this.currentContext == this.community) ? "communities" : "users";
        }
    },
    methods: {
        ajaxCreateRequest: function () {
            let servers = this.newRouteServerRadio;
            if (this.newRouteServerRadio === "custom") {
                servers = this.newRouteServers.map(server => server.id);
            }

            let types = this.newRouteTypesRadio;
            if (this.newRouteTypesRadio === "custom") {
                types = this.newRouteTypes.map(type => type.id);
            }

            let data = {
                channel_id: this.newRouteChannel.id,
                server_ids: servers,
                types: types
            };

            if (this.currentContext === this.community) {
                data.user_ids = this.newRouteUsers.map(user => user.id);
            } else {
                data.community_id = this.newRouteCommunity.id;
            }

            this.newRouteInputDisabled = true;
            axios.post(
                `/${this.urlPrefix}/${this.currentContext.public_id}/notification_routing`,
                data
            ).then((res) => {
                UIkit.modal($("#create-new-route-modal")).hide();

                Vue.set(this.newRouteChannel, "routes", res.data.routes);
                this.resetNewRouteVars();

                Toaster.success(res.data.message);
            }).catch((error) => {
                Toaster.error(DEFAULT_ERROR);
                console.log(error.toJSON());
            }).finally(() => {
                this.newRouteInputDisabled = false;
            });
        },
        ajaxUpdateRequest: function (request, action) {
            this.pendingRequestsInputDisabled = true;
            let typeIds = _.map(_.filter(request.types, "selected"), "id");

            axios.patch(
                // action: accept, decline
                `/${this.urlPrefix}/${this.currentContext.public_id}/notification_routing/${action}_requests`,
                { ids: typeIds }
            ).then((res) => {
                // Remove the request
                if (typeIds.length === request.types.length) {
                    Vue.delete(this.pendingRequests, _.indexOf(this.pendingRequests, request));
                } else {
                    typeIds.forEach((id) => {
                        Vue.delete(request.types, _.findIndex(request.types, { id: id }));
                    });
                }

                // The selected types will be removed but the select all will still show partially checked
                this.requestSelectAllCheckbox(request).indeterminate = false;
                this.updateAvailableRoutes(typeIds, action);

                Toaster.success(res.data.message);
            }).catch((error) => {
                Toaster.error(DEFAULT_ERROR);
                console.log(error);
            }).finally(() => {
                this.pendingRequestsInputDisabled = false;
            });
        },
        ajaxUpdateAllRequests: function (action) {
            if (!confirm(`Are you sure you want to ${action} all pending requests?`)) return;

            this.pendingRequestsInputDisabled = true;

            axios.put(
                // action: accept, decline
                `/${this.urlPrefix}/${this.currentContext.public_id}/notification_routing/${action}_all_requests`
            ).then((res) => {
                let typeIds = _.flatMap(this.pendingRequests, (request) => {
                    return _.map(request.types, (type) => type.id);
                });

                this.updateAvailableRoutes(typeIds, action);

                this.pendingRequests = [];
                Toaster.success(res.data.message);
            }).catch((error) => {
                Toaster.error(DEFAULT_ERROR);
                console.log(error);
            }).finally(() => {
                this.pendingRequestsInputDisabled = false;
            });
        },
        ajaxUpdateRouteType: function (type) {
            axios.patch(
                `/${this.urlPrefix}/${this.currentContext.public_id}/notification_routing/${type.id}`,
                { enabled: !type.enabled }
            ).then((res) => {
                Toaster.success(res.data.message);
            }).catch((error) => {
                Toaster.error(DEFAULT_ERROR);
                console.log(error);
            });
        },
        ajaxDeleteRoute: function (route, type) {
            if (!confirm(`Are you sure you want to stop routing ${type.name} notifications?`)) return;

            Vue.set(type, "deleted", true);

            axios.delete(
                `/${this.urlPrefix}/${this.currentContext.public_id}/notification_routing/${type.id}`
            ).then((res) => {
                _.pull(this.editRouteSelectedServer.types, type);

                // Remove the server if it's empty
                if (_.isEmpty(this.availableTypes(this.editRouteSelectedServer))) {
                    _.pull(route.servers, this.editRouteSelectedServer);
                }

                Toaster.success(res.data.message);
            }).catch((error) => {
                Toaster.error(DEFAULT_ERROR);
                console.log(error);
                Vue.delete(type, "deleted");
            });
        },
        ajaxDeleteAllTypes: function (route) {
            if (!confirm(`Are you sure you want to stop routing all of ${route.user.name}'s notifications from ${this.editRouteSelectedServer.name}?`)) return;

            Vue.set(this.editRouteSelectedServer, "deleted", true);

            axios.delete(
                `/${this.urlPrefix}/${this.currentContext.public_id}/notification_routing/destroy_many`,
                {
                    data: {
                        ids: _.map(this.editRouteSelectedServer.types, "id")
                    }
                }
            ).then((res) => {
                // Remove the server
                _.pull(route.servers, this.editRouteSelectedServer);

                Toaster.success(res.data.message);
            }).catch((error) => {
                Toaster.error(DEFAULT_ERROR);
                console.log(error);
                Vue.delete(this.editRouteSelectedServer, "deleted");
            });
        }
    }
}
