import Vue from "vue/dist/vue.esm.js";
import _ from "lodash";
import { getData } from "../utilities";

// This is binding to a class name, so elem is actually a DOM object
export default function (elem) {
  let data = getData(elem, [
    "communities",
    "servers",
    "default-community",
    "default-server",
  ]);

  const app = new Vue({
    el: elem,
    data: {
      communities: data.communities,
      servers: data.servers,
      defaultCommunity: data["default-community"],
      defaultServer: data["default-server"],
    },
    methods: {},
  });
}
