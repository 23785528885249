import Vue from 'vue/dist/vue.esm.js';
import {
    getData
} from "../utilities";

export default function (elem) {
    let data = getData(elem, ["server", "setting", "mod", "mods", "reward", "community-id", "server-names"]);

    const app = new Vue({
        el: elem,
        data: {
            community_id: data["community-id"],
            server_names: data["server-names"],
            server: data.server,
            reward: data.reward,
            setting: data.setting,
            mods: data.mods || [{
                mod_name: "@ExileMod",
                mod_version: "1.0.4",
                mod_link: "https://steamcommunity.com/sharedfiles/filedetails/?id=1487484880",
                mod_required: true
            }],
            mod: _.clone(data.mod),
            reward_item: {
                quantity: 1,
                classname: ""
            },
            errors: {
                server_id: [],
                server_address: []
            },
            server_id: "",
            processing: false
        },
        mounted: function() {
            if (_.startsWith(this.server.server_id, `${this.community_id}_`)) {
                Vue.set(this.server, "server_id", this.server.server_id.replace(`${this.community_id}_`, ""));
            }

            // Load the default error messages
            if (_.isEmpty(this.server.server_id)) {
                this.checkServerID();
            } else {
                // Edit page: Store the current server_id to keep the checks from failing due to the server_id already existing
                this.server_id = _.clone(this.server.server_id);
            }

            if (_.isEmpty(this.server.server_ip)) {
                this.checkServerAddress();
            }
        },
        computed: {
            disableModPersistButton: function() {
                if (this.processing) return true;
                if (_.isEmpty(this.mod.mod_name)) return true;

                // Don't allow multiple mods with the same name
                // Ignoring the one we're currently editing
                if (_.size(_.filter(this.mods, { mod_name: this.mod.mod_name })) >= 2) return true;

                return false;
            },
            disableSaveServerButton: function () {
                if (this.processing) return true;

                // Check for existance
                if (_.isEmpty(this.server.server_id)) return true;
                if (_.isEmpty(this.server.server_ip)) return true;
                if (_.isEmpty(this.server.server_port)) return true;

                // Check values
                if (!_.isEmpty(this.errors.server_id) || !_.isEmpty(this.errors.server_address)) return true;

                // Check the rewards
                if (this.reward.player_poptabs < 0) return true;
                if (this.reward.locker_poptabs < 0) return true;
                if (this.reward.respect < 0) return true;

                // Check each item quantity
                _.each(this.reward.reward_items, (quantity, item) => {
                    if (quantity < 0 || _.isEmpty(item)) return true;
                });

                return false;
            }
        },
        methods: {
            checkServerID: function() {
                Vue.set(this.errors, "server_id", []);

                if (_.isEmpty(this.server.server_id)) {
                    this.errors.server_id.push("Please provide a server ID");
                    return
                }

                if (/[\s]+/i.test(this.server.server_id)) {
                    this.errors.server_id.push("Server ID cannot contain whitespace");
                }

                if (/[\W]+/i.test(this.server.server_id)) {
                    this.errors.server_id.push("Server ID cannot contain any symbols");
                }

                if (this.server_id != this.server.server_id && this.server_names.includes(`${this.community_id}_${this.server.server_id}`)) {
                    this.errors.server_id.push("This server ID already exists");
                }
            },
            checkServerAddress: function () {
                Vue.set(this.errors, "server_address", []);

                if (_.isEmpty(this.server.server_ip) && _.isEmpty(this.server.server_port)) {
                    this.errors.server_address.push("Please provide the server's IP address and port");
                    return
                }

                if (!(/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/i.test(this.server.server_ip))) {
                    this.errors.server_address.push("Please provide a valid public facing IPv4 address");
                }

                if (!(/\d+/i.test(this.server.server_port))) {
                    this.errors.server_address.push("Please provide a valid network port");
                }
            },
            resetMod: function() {
                this.mod = _.clone(data.mod);
            },
            addMod: function () {
                this.mods.push(this.mod);

                UIkit.modal($("#add-mod")).hide();
                Toaster.success(`Mod <code>${this.mod.mod_name}</code> added`);

                this.resetMod();
            },
            removeMod: function (name) {
                Vue.delete(this.mods, _.findIndex(this.mods, { mod_name: name }));
            },
            editMod: function (name) {
                this.mod = _.clone(_.find(this.mods, { mod_name: name }));
                Vue.set(this.mod, "_previous_name", name);
                UIkit.modal($("#edit-mod")).show();
            },
            saveMod: function () {
                if (this.mod._previous_name !== this.mod.mod_name) {
                    this.removeMod(this.mod.mod_name);
                } else {
                    this.removeMod(this.mod._previous_name);
                }

                this.mods.push(this.mod);

                UIkit.modal($("#edit-mod")).hide();
                Toaster.success(`Mod <code>${this.mod.mod_name}</code> updated`);

                this.resetMod();
            },
            addItemReward: function () {
                if (this.reward.reward_items.hasOwnProperty(this.reward_item.classname)) {
                    return Toaster.warn("You already have this item as a reward.");
                }

                if (_.isEmpty(this.reward_item.classname)) {
                    return Toaster.warn("Please provide a classname");
                }

                if (this.reward_item.quantity === "" || this.reward_item.quantity < 1) {
                    return Toaster.warn("Please provide a quantity that is greater than 0");
                }

                Vue.set(this.reward.reward_items, this.reward_item.classname, this.reward_item.quantity);
                this.reward_item = {
                    quantity: 1,
                    classname: ""
                };
            },
            removeItemReward: function (classname) {
                if (!this.reward.reward_items.hasOwnProperty(classname)) {
                    return Toaster.error("This classname does not exist in the reward");
                }

                Vue.delete(this.reward.reward_items, classname);
            },
            setProcessing: function (state) {
                if (state) {
                    $(".dashboard-panel").addClass("uk-hidden");
                    $("#mobile-panel").fadeOut();
                    $("#spinner").removeClass("uk-hidden");
                } else {
                    $(".dashboard-panel").removeClass("uk-hidden");
                    $("#mobile-panel").fadeIn();
                    $("#spinner").addClass("uk-hidden");
                }

                this.processing = state;
            }
        }
    });
}
