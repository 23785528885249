import Vue from 'vue/dist/vue.esm.js';
import {
    getData
} from "../utilities";

// This is binding to a class name, so elem is actually a DOM object
export default function (elem) {
    let data = getData(elem, ["roles", "selected-roles"]);

    _.each(data.roles, function (role) {
        role.selected = _.some(data["selected-roles"], { id: role.id });
    });

    const app = new Vue({
        el: elem,
        data: {
            roles: data.roles,
            selectedRole: null
        },
        mounted: function () {
            this.setDefaultSelectedRole();
        },
        computed: {
            selectedRoles: function () {
                return _.filter(data.roles, { selected: true })
            },
            selectedRoleIDs: function () {
                return _.map(this.selectedRoles, "id");
            },
            disableAddButton: function () {
                return _.isUndefined(this.selectedRole);
            }
        },
        methods: {
            setDefaultSelectedRole: function () {
                this.selectedRole = _.isEmpty(this.roles) ? null : _.find(this.roles, { selected: false })?.id;
            },
            addRole: function () {
                let role = _.find(this.roles, { id: this.selectedRole });
                Vue.set(role, "selected", true);

                this.setDefaultSelectedRole();
            },
            removeRole: function (role) {
                Vue.set(role, "selected", false);
                this.setDefaultSelectedRole();
            }
        }
    });
}
